import persistedRegistrationReducer from "@/store/features/registration";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import process from "process";
import {
    FLUSH,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    REHYDRATE
} from "redux-persist";

import checkoutReducer from "./features/checkout";

const isInDevEnv = () =>
    /\b(dev|dev[123]|rc)\b/.test(process.env.APP_URL ?? "");

export const rootReducer = combineReducers({
    checkout: checkoutReducer,
    registration: persistedRegistrationReducer
});

export const store = configureStore({
    reducer: rootReducer,
    devTools: isInDevEnv(),
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER
                ]
            }
        })
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
